<template>
  <div class="home hero-bg animate__animated animate__fadeIn animate__slow">
    <section class="hero is-medium">
      <!-- Hero head: will stick at the top -->
      <div class="hero-head">
        <div class="container is-max-widescreen">
          <Navbar />
        </div>
      </div>

      <!-- Hero content: will be in the middle -->
      <div class="hero-body">
        <div class="container is-max-widescreen">
          <div class="my-6" />
          <div class="columns">
            <div class="column">
              <div class="content">
                <p
                  class="title is-size-1-mobile has-text-black"
                  style="font-size: 4rem"
                >
                  Hi, I'm <span class="accent-line">Julian</span> <br />
                  Another passionate Full-stack designer.
                </p>
                <div class="tags are-medium personal-tags">
                  <span class="tag is-white has-text-grey-light"
                    >UI/UX Designer</span
                  >
                  <span class="tag is-white has-text-grey-light">/</span>
                  <span class="tag is-white has-text-grey-light"
                    >Product Designer</span
                  >
                  <span class="tag is-white has-text-grey-light">/</span>
                  <span class="tag is-white has-text-grey-light"
                    >Problem Solver</span
                  >
                  <span class="tag is-white has-text-grey-light">/</span>
                  <span class="tag is-white has-text-grey-light"
                    >CSS Lover</span
                  >
                  <span class="tag is-white has-text-grey-light">/</span>
                  <span class="tag is-white has-text-grey-light"
                    >Coffee Lover</span
                  >
                </div>
                <div class="mt-6 contact-me is-size-6 has-text-weight-bold">
                  <a href="mailto:julianatwork365@gmail.com">Contact me ➔</a>
                </div>
              </div>
            </div>
            <div class="column is-one-third is-hidden-touch">
              <video width="480" height="480" autoplay muted loop>
                <source src="img/avatar.mp4" type="video/mp4" />
              </video>
            </div>
          </div>

          <!-- <figure class="image is-128x128">
            <img src="img/demo.usdz" />
          </figure> -->
        </div>
      </div>
    </section>

    <hr style="margin: 6rem 0" />

    <div class="container is-fluid">
      <div class="container is-max-widescreen mt-6">
        <p class="subtile is-2 has-text-weight-bold has-text-black">
          Featured Works
        </p>
        <Features />

        <div class="buttons is-justify-content-center">
          <a class="button is-link is-light" @click="$router.push('/work')">More works</a>
        </div>

      </div>
    </div>

    <Footer />
  </div>
</template>

<script>
// @ is an alias to /src
import Features from "@/components/Features.vue";
import Navbar from "@/components/Navbar.vue";
import Footer from "@/components/Footer.vue";

export default {
  name: "Home",
  components: {
    Features,
    Navbar,
    Footer,
  },
  mounted() {
    this.track();
  },
  methods: {
    track() {
      this.$gtag.pageview("/");
      this.$gtag.query('event', 'screen_view',{
        app_name: 'juliandesign.io',
        screen_name: 'Home',
      })
    },
  }
};
</script>

<style scoped>
/* .home {
  background-color: black;
} */
.personal-tags .tag:not(body) {
  padding-left: 0;
}
</style>
