<template>
  <div class="features">
    <div class="columns my-6 animate__animated animate__fadeInUp animate__slow" v-show="items.isFeatured" v-for="(items, index) in featureList" :key="index" @click="handleViewWork(items.path)">
      <div class="column is-one-quarter">
        <section class="section p-0">
          <p class="heading">Featured</p>
          <p class="subtitle is-size-4 has-text-weight-bold has-text-black">
           {{items.name}}
          </p>
          <hr width="50px"/>
          <div class="tags">
            <span class="tag is-light" v-for="item in items.tags" :key="item">{{item}}</span>
          </div>
        </section>
      </div>
      <div class="column is-three-quarters">
        <figure class="image">
          <img class="img-radius-10 img-border" :src="items.cover" />
        </figure>
      </div>
    </div>
  </div>
</template>

<script>
import source from '../views/data/works.json';
export default {
  name: "features",
  props: {
    msg: String,
  },
  data() {
    return {
      featureList:source,
    };
  },
  methods: {
    handleViewWork(path) {
      this.$router.push(path);
    }
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
</style>
